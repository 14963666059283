import { useEffect, useRef, useState } from "react";
import { buyVoucherApi, getVouchersApi, getWatchUrl, validateResponse } from "../network/apiCalls";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CategoryBox from "../components/CategoryBox";
import PlanPicker, { PaymentModes } from "../components/PlanPicker";
import { fetchUser } from "../redux/features/authSlice";
import SiteModals from "../components/Modals";
import { fileURL } from "../utils/helpers";
import Swal from "sweetalert2";
const Voucher = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const websiteData = useSelector((state) => state.websiteData);
  const [master, setMaster] = useState(undefined);
  const [vouchers, setVouchers] = useState([]);
  const [activeVoucher, setActiveVoucher] = useState(null);
  const [modalShow, showModal] = useState(false);
  const [payMode, setPaymode] = useState(null);
  const [watchUrl, setWatchUrl] = useState('');
  const fetchVoucher = async (categoryID) => {
    try {
      let response = await getVouchersApi(categoryID);
      if (response.status == 200 && response.data.status == true) {
        setVouchers(response.data.data);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
      validateResponse(err.response)
    }
  };
  const fetchWatchUrl = async () => {
    try {
      let response = await getWatchUrl();
      if (response.status == 200 && response.data.status == true) {
        setWatchUrl(response.data.upay);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    setMaster(websiteData.masterCategory.find((item) => item.id == id));
    fetchVoucher(id);
    fetchWatchUrl();
  }, [websiteData]);

  function invalidPayment() {
    if (activeVoucher == null || payMode == null) return true;
    if (
      payMode == "wallet" &&
      activeVoucher.price > auth.userInfo.wallet_balance
    )
      return true;
    return false;
  }

  const askConfirm  = () => {
    return new Promise((resolve,reject)=>{
      Swal.fire({
        title: "",
        text: "আপনি কি নিশ্চিত কিনতে চান?.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00960d",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText:"No"
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true)
        }else{
          reject(false);
        }
      });
    })
    
  }
  const buyNow = async (voucher_id,price) => {
    try{
     await askConfirm();
    }catch(e){
      return false;
    } 
    if (auth.userToken == null) {
      toast.error("Please login to continue");
      return false;
    }

    if(parseInt(price) > parseInt(auth.userInfo.wallet_balance)){
      showModal(true)
      return;
    }
    
    let requestData = {voucher_id:voucher_id};
    try{
      let response = await buyVoucherApi(requestData);
      console.log(response);
      toast.success(response.data.message);
      dispatch(fetchUser());
      navigate("/profile/userCode");
    }catch(error){
      toast.error(error);
    }
  };
  if (master == undefined) {
    return null;
  }
  return (
    <>
      <div className="container-fluid bg-white">
        <div className="container py-5">
          <div className="d-flex">
            <img
              src={master.image}
              style={{ height: "140px" }}
              className="rounded"
            />
            <div className="p-3">
              <div className="d-flex flex-wrap align-items-center">
                <small className="fs-2 text-black me-2">{master.cname}</small>{" "}
                  <Link to={"/"}>{"(Code)"}</Link>
              </div>
              <div className="d-flex align-items-center">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/plasticine/100/000000/lightning-bolt--v1.png"
                  alt="lightning-bolt--v1"
                />
                <span className="text-black">Instant Delivery</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column">
              {vouchers.length > 0 &&
                vouchers.map((item, index) => {
                  return (
                    <div key={index} className="bg-white rounded p-3 m-1 my-2 border">
                      <div
                        
                            className={
                            "d-flex flex-row flex-wrap"
                            }
                      >
                        <div
                          className="mx-2"
                          style={{ width: "80px", textAlign: "center" }}
                        >
                          <img
                            src={item.image}
                            style={{ height: "70px", maxWidth: "100%" }}
                            className="rounded"
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fs-6 text-black fw-bold">
                            {item.voucher_name} <br />
                            {item.stock > 0 ? (
                              <span className="badge bg-success">In Stock</span>
                            ) : (
                              <span className="badge bg-danger">
                                Out of Stock
                              </span>
                            )}
                          </span>

                          <span className="text-black d-flex align-items-center">
                          <img
                            width="20"
                            height="20"
                            src={'/static/icons/taka-symbol.png'}
                            style={{ width: "30px", objectFit: "contain" }}
                          />
                            {item.price}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                            <button className="btn btn-primary btn-sm m-1 flex-grow-1 p-1 p-lg-2" onClick={()=>buyNow(item.voucher_id,item.price)}><i className="fa fa-shopping-cart"></i> Buy</button>
                            <a href={watchUrl} target="_blank" className="btn btn-sm btn-primary m-1 flex-grow-1 p-1 p-lg-2"><i className="fa fa-eye"></i> Watch</a>
                            <a
                              className="btn btn-sm btn-primary m-1 flex-grow-1 p-1 p-lg-2"
                              data-bs-toggle="collapse"
                              href={"#collapseExample" + index}
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                             <i className="fa fa-file"></i> Rules</a>
                        </div>
                      <div className="collapse" id={"collapseExample" + index}>
                        <div
                          className="card border-0 text-black border-top rounded-0 mt-2 pt-2"
                          {...{
                            dangerouslySetInnerHTML: {
                              __html: item.description,
                            },
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {modalShow ? (
        <div className="modal show" tabIndex="-1" style={{display:'block',background:'#00000070'}}>
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                onClick={()=>showModal(false)}
              ></button>
            </div>
            <div className="modal-body text-center">
              <img src={fileURL('/static/media/logo-cropped.png')} height={120}/>
              <p>আপনার একাউন্ট এ পর্যাপ্ত পরিমাণ টাকা<br/>জমা নেই। অনুগ্রহ করে টাকা যোগ করুন।</p>
              <Link className="btn btn-primary" to={'/profile/addMoney'}>ADD AMOUNT IN WALLET </Link>
            </div>
          </div>
        </div>
      </div>
      ):null}
      
    </>
  );
};
export default Voucher;
