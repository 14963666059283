import { userOrderListApi, validateResponse } from "../network/apiCalls";
import { useEffect, useState } from "react";
import getStatus from "../utils/helpers";
const UserOrder = () => {
  const [orders, setOrders] = useState([]);

  const fetchOrdersHistory = async () => {
    try{
      let response = await userOrderListApi();
      if (response.status == 200 && response.data.status == true) {
        let res = response.data.data.sort(function(a,b){
          if(a.order_id > b.order_id)
            return -1;
          else if(a.order_id < b.order_id)
            return 1;
          else 
            return 0;
        });
        setOrders(res);
      }
    }catch(e){
      validateResponse(e.response);
    }
    
  };
  useEffect(() => {
    fetchOrdersHistory();
  }, []);
  return (
    <>
      <div className="row">
        <div className="mx-lg-5 rounded shadow border">
          <p className="text-black fs-4">
            <i className=" fa fa-solid fa-shopping-cart"></i> &nbsp;&nbsp;My Orders
          </p>
          <div className="table-wrapper">
            <div id="table-scroll">

                  {orders.map((res,index) => {
                    return (
                        <table key={index}
                        className="border text-black w-100 mb-4"
                        cellPadding={5}>
                          <tbody>
                            <tr><th style={{width:'110px'}}>DateTime</th><td>{res.created_at}</td></tr>
                            <tr><th>Status</th><td>{getStatus(res.status, "order")}</td></tr>
                            <tr><th>Amount</th><td>{res.paid_amount}</td></tr>
                            <tr><th>OrderId</th><td>{res.order_id}</td></tr>
                            <tr><th>Plan</th><td>{res.plan_name}</td></tr>
                            <tr><th>PlayerId</th><td>{res.saccount_id}</td></tr>
                            <tr><th>Remarks</th><td>{res.remark}</td></tr>
                          </tbody>
                        </table>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserOrder;
