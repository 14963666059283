import { useEffect, useRef, useState } from "react";
import {
  getPlanListApi,
  requestApi,
  requestDirectPayApi,
  validateResponse,
} from "../network/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../redux/features/authSlice";

const PlanPicker = ({ planId, title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUID = typeof(title) == 'string' ? (title.toUpperCase() == 'UID TOPUP') : false;
  const auth = useSelector((state) => state.auth);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payMode, setPaymode] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [sdata, setSdata] = useState({
    playerId: "",
    aType: "",
    aPassword: "",
    aBackupCode: "",
  });
  const fetchPlans = async (id) => {
    setLoading(true);
    try {
      let response = await getPlanListApi(id);
      if (response.status == 200 && response.data.status == true) {
        setPlans(response.data.data);
      }
    } catch (err) {
      // toast.error(err.message);
      console.log(err);
      validateResponse(err.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setActivePlan(null);
    fetchPlans(planId);
  }, [planId]);

  function invalidPayment() {
    if (activePlan == null || payMode == null) return true;
    if (payMode == "wallet" && activePlan.price > auth.userInfo.wallet_balance)
      return true;
    return false;
  }

  const buyNow = async () => {
    if (auth.userToken == null) {
      toast.error("Please login to continue");
      return false;
    }
    if(isUID){
      if (sdata.playerId == "") {
        toast.error("Please fill player ID");
        return false;
      }
    }else{
      if(sdata.aType == "") {
        toast.error("Please choose account type");
        return false;
      }
      if(sdata.playerId == "") {
        toast.error("Please fill email");
        return false;
      }
      if(sdata.aPassword == "") {
        toast.error("Please fill password");
        return false;
      }
    }
    
    
    let requestData = {
      plan_id: activePlan.plans_id,
      paid_amount: 0,
      payment_reference: 0,
      saccount_type: (sdata.aType+" Game Name : Game Name" + (isUID ? "":" , Backup Code:"+sdata.aBackupCode)).trim(),
      saccount_id: isUID ? " UID : " + sdata.playerId : sdata.playerId,
      saccount_password: isUID ? 0 : sdata.aPassword,
      backup_code: isUID ? "" : sdata.aBackupCode,
      coupan_code: "",
      paid_from_wallet: activePlan.price,
    };
    if (payMode == "wallet") {
      try {
        let response = await requestApi(requestData);
        console.log(response);
        toast.success(response.data.message);
        dispatch(fetchUser());
        navigate("/profile/userOrder");
      } catch (error) {
        toast.error(error);
        validateResponse(error.response);
      }
    } else if (payMode == "bikas") {
      try {
        let response = await requestDirectPayApi(
          requestData.paid_from_wallet,
          requestData
        );
        let respData = response.data;
        if (respData.status == true) {
          window.open(respData.payment_url);
        } else {
          toast.error(respData.message);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.message);
        validateResponse(e.response);
      }
    }
  };

  function changeAccountType(type) {
    setSdata({ ...sdata, aType: type });
  }

  const paymentBoxRef = useRef(null);
  const scrollToPayment = () => {
    paymentBoxRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  if (loading) {
    return (
      <img src="public/wp-content/plugins/elementor/assets/images/ajax-loader.gif" />
    );
  }

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-8 d-flex flex-column">
          {plans.length > 0 &&
            plans.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    "bg-white rounded p-3 m-1 my-2 d-flex flex-row flex-wrap" +
                    (item.plans_id == activePlan?.plans_id
                      ? " selected-box"
                      : "")
                  }
                  onClick={() => {
                    setActivePlan(item);
                    scrollToPayment();
                  }}
                >
                  <div
                    className="mx-2"
                    style={{ width: "80px", textAlign: "center" }}
                  >
                    <img
                      src={item.image}
                      style={{ height: "70px", maxWidth: "100%" }}
                      className="rounded"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <span className="fs-6 text-black fw-bold">
                      {item.pname}
                    </span>
                    {item.no_of_diamonds > 0 && (
                      <span className="text-black d-flex align-items-center">
                        <img
                          width="25"
                          height="25"
                          src="https://img.icons8.com/color/48/diamond.png"
                          alt="diamond"
                          style={{ width: "30px", objectFit: "contain" }}
                        />{" "}
                        {item.no_of_diamonds}
                      </span>
                    )}
                    <span className="text-black d-flex align-items-center">
                      <img
                        width="20"
                        height="20"
                        src={'/static/icons/taka-symbol.png'}
                        style={{ width: "30px", objectFit: "contain" }}
                      />
                      {item.price}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div ref={paymentBoxRef} className="col-lg-4">
          <div className="card">
            <div className="card-header bg-white text-primary">
              Provide Order Details
            </div>
            <div className="card-body">
              {isUID ? (
              <div className="form-group">
                <label className="text-black">Player ID/UID Code</label>
                <input
                  type="text"
                  onChange={(e) =>
                    setSdata({ ...sdata, playerId: e.target.value })
                  }
                  value={sdata.playerId}
                  className="form-control"
                />
            </div>
              ):(
              <div>
                <div className="form-group">
                  <label className="text-black">Account Type</label>
                  <div className="d-flex gap-2">
                    <button
                      className={
                        "p-1 btn btn-" +
                        (sdata.aType == "FB" ? "" : "outline-") +
                        "primary"
                      }
                      type="button"
                      onClick={() => changeAccountType("FB")}
                    >
                      FB
                    </button>
                    <button
                      className={
                        "p-1 btn btn-" +
                        (sdata.aType == "Google" ? "" : "outline-") +
                        "primary"
                      }
                      type="button"
                      onClick={() => changeAccountType("Google")}
                    >
                      Google
                    </button>
                    <button
                      className={
                        "p-1 btn btn-" +
                        (sdata.aType == "VK" ? "" : "outline-") +
                        "primary"
                      }
                      type="button"
                      onClick={() => changeAccountType("VK")}
                    >
                      VK
                    </button>
                    <button
                      className={
                        "p-1 btn btn-" +
                        (sdata.aType == "Twitter" ? "" : "outline-") +
                        "primary"
                      }
                      type="button"
                      onClick={() => changeAccountType("Twitter")}
                    >
                      Twitter
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-black">Enter Email</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setSdata({ ...sdata, playerId: e.target.value })
                    }
                    value={sdata.playerId}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="text-black">Enter Password</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setSdata({ ...sdata, aPassword: e.target.value })
                    }
                    value={sdata.aPassword}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label className="text-black">
                    Enter Backup Code(Optional)
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setSdata({ ...sdata, aBackupCode: e.target.value })
                    }
                    value={sdata.aBackupCode}
                    className="form-control"
                  />
                </div>
              </div>
              )}
              
              
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-header bg-white text-primary">Payment</div>
            <div className="card-body">
              <p className="card-title d-flex justify-content-between text-grey fs-5">
                Total
                <span style={{fontSize:14}}>{activePlan?.pname}</span>
                <strong className="text-danger">
                    <img
                        width="20"
                        height="20"
                        src={'/static/icons/taka-symbol.png'}
                        style={{ width: "30px", objectFit: "contain" }}
                      />
                  {activePlan?.price ?? 0}
                </strong>
              </p>
              <p>Payment Mode</p>
              <PaymentModes payMode={payMode} setPaymode={setPaymode} />
              <div className="d-grid my-3">
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={invalidPayment()}
                  onClick={() => buyNow()}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanPicker;

export const PaymentModes = ({ payMode, setPaymode }) => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      <div className="d-flex flex-row">
        <div className="w-50" onClick={() => setPaymode("wallet")}>
          <div
            className={
              "bg-light rounded m-2" +
              (payMode == "wallet" ? " selected-box" : "")
            }
          >
            <div className=" d-flex flex-row text-center">
              <img
                src="/static/media/logo-cropped.png"
                style={{ height: "77px", objectFit: "contain" }}
              />
              <div className="d-flex flex-grow-1">
                <span className="display-6 flex-grow-1 d-flex justify-content-center align-items-center">
                  {auth.userInfo.wallet_balance}
                </span>
              </div>
            </div>
            <div className="border-top text-center text-small">
              Pay from wallet
            </div>
          </div>
        </div>
        <div className="w-50" onClick={() => setPaymode("bikas")}>
          <div
            className={
              "bg-light rounded m-2" +
              (payMode == "bikas" ? " selected-box" : "")
            }
          >
            <img
              src="https://i.ibb.co/mCtC0GF/bkash-pay.png"
              className="w-100"
            />
            <div className="border-top text-center text-small">
              Instant Payment
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
